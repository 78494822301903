import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AdminLayout from "./layouts/Admin/Admin.js";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import AuthRoute from './components/Auth/AuthRoute';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import tokenService from './services/api/token_service';
import CustomSnackbar from './components/Snackbar/CustomSnackbar';
import AuthProvider from "./provider/authProvider";
import Message from './components/Message/Message';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = React.lazy(() => import('./views/signup/signup'))
const Login = React.lazy(() => import('./views/login/login'))

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

export default function App() {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', color: 'success' as 'success' | 'danger' | 'warning' | 'primary' | 'neutral' });
    async function requestPermission() {
        //requesting permission using Notification API
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID_KEY,
            });

            let tokenInArr = [token]

            //We can send token to server
            console.log("Token generated : ", tokenInArr);
            if (localStorage.getItem("isAuthenticated") == "true") {
                AddFirebaseToken(tokenInArr)
                localStorage.setItem('firebase_token', token)
            }
        } else if (permission === "denied") {
            //notifications are blocked
            alert("You denied for the notification");
        }
    }
    function handleSnackbarClose() {
        setSnackbar({ ...snackbar, open: false });
    };

    async function AddFirebaseToken(firebase_token: any) {
        try {
            const data = await tokenService.addFirebaseToken({ firebase_token })
            if (data.status === 200 || data.status === 201) {
            }
            else if (data.status == 422) {
                setSnackbar({ open: true, message: data?.message, color: 'warning' });
            }
            else {
                setSnackbar({ open: true, message: data?.message, color: 'danger' });
            }
        }
        catch (e: any) {
            console.log(JSON.stringify(e))
            setSnackbar({ open: true, message: e?.message, color: 'danger' });
        }
    }

    onMessage(messaging, (payload) => {
        console.log("incoming msg");
        toast(<Message notification={payload.notification} />, { autoClose: false, progress: 1, theme: 'dark', position: 'top-center' });
    });

    useEffect(() => {
        return () => {
            requestPermission();
        }
    }, []);
    return (
        <ThemeContextWrapper>
            <BackgroundColorWrapper>
                <Router>
                    <CustomSnackbar
                        open={snackbar.open}
                        onClose={handleSnackbarClose}
                        message={snackbar.message}
                        color={snackbar.color}
                    />
                    <AuthProvider>
                        <Suspense fallback={loading}>
                            <Routes>
                                <Route path="/login" element={<AuthRoute><Login /></AuthRoute>} />
                                <Route path="/new-signup" element={<AuthRoute><Signup /></AuthRoute>} />
                                <Route path="/admin/*" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>} />
                                <Route
                                    path="*"
                                    element={<ProtectedRoute><Navigate to="/admin/dashboard" replace /></ProtectedRoute>}
                                />
                            </Routes>
                        </Suspense>
                    </AuthProvider>
                </Router>
            </BackgroundColorWrapper>
        </ThemeContextWrapper>
    )
}