import React from 'react'
const Dashboard = React.lazy(() => import('./views/dashboard/dashboard'))
const UserProfile = React.lazy(() => import('./views/userprofile/user-profile'))
const MyTask = React.lazy(() => import('./views/mytask/my-task'))
const Setting = React.lazy(() => import('./views/setting/setting'))
const Birthday = React.lazy(() => import('./views/birthday/birthday'))
const Transport = React.lazy(() => import('./views/transport/transport'))

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/my-tasks",
    name: "My Tasks",
    icon: "tim-icons icon-atom",
    component: <MyTask />,
    layout: "/admin",
  },
  {
    path: "/birthday",
    name: "Birthday",
    icon: "tim-icons icon-atom",
    component: <Birthday />,
    layout: "/admin",
  },
  {
    path: "/transport",
    name: "Transport",
    icon: "tim-icons icon-atom",
    component: <Transport />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Setting",
    icon: "tim-icons icon-align-center",
    component: <Setting />,
    layout: "/admin",
  },
];
export default routes;
