import axios from 'axios';
import * as Api from '../../helpers/constant/api_const'

// Create an instance of axios with custom config
const api = axios.create({
  baseURL: Api.baseURL, // Replace with your API's base URL
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Do something before request is sent, like adding an auth token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

// Define your API methods
const apiService = {
  getData: async (endpoint: any) => {
    try {
      const response = await api.get(endpoint);
      return response.data;
    } catch (error) {
      console.error('API GET Error:', error);
      throw error;
    }
  },
  postData: async (endpoint: any, data: any) => {
    try {
      const response = await api.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error('API POST Error:', error);
      throw error;
    }
  },
  postDataCustomHeader: async (endpoint: any, data: any, header: any) => {
    try {
      const response = await api.post(endpoint, data, header);
      return response.data;
    } catch (error) {
      console.error('API POST Custom Header Error:', error);
      throw error;
    }
  },
  putData: async (endpoint: any, data: any) => {
    try {
      const response = await api.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.error('API PUT Error:', error);
      throw error;
    }
  },
  patchData: async (endpoint: any, data: any) => {
    try {
      const response = await api.patch(endpoint, data);
      return response.data;
    } catch (error) {
      console.error('API PATCH Error:', error);
      throw error;
    }
  },
  deleteData: async (endpoint: any) => {
    try {
      const response = await api.delete(endpoint);
      return response.data;
    } catch (error) {
      console.error('API DELETE Error:', error);
      throw error;
    }
  },
};

export default apiService;