import React from 'react';
import Snackbar from '@mui/joy/Snackbar';

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  color: 'success' | 'danger' | 'warning' | 'primary' | 'neutral';
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, onClose, message, color }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={"top" + "right"}
      color={color}
      variant='solid'
    >
      {message}
    </Snackbar>
  );
};

export default CustomSnackbar;
