import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import tokenService from "../services/api/token_service";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
    const [token, setToken_] = useState(localStorage.getItem("token"));

    function setToken(newToken) {
        setToken_(newToken);
    };

    function logout() {
        localStorage.clear();
        window.location.href = '/login'
    };

    async function refreshToken() {
        try {
            const data = await tokenService.refreshToken(localStorage.getItem('refreshToken'))
            if (data.status === 200) {
                localStorage.setItem("token", data.data.accessToken);
            }
        } catch (error) {
            console.error(error);
            logout();
        }
    };

    useEffect(() => {
        if (token || localStorage.getItem('refreshToken')) {
            const decodedToken = jwtDecode(token);

            const tokenExp = new Date(decodedToken.exp * 1000);
            const now = new Date();

            // Refresh token if it's about to expire(less than 1 min)
            if (tokenExp - now < 60 * 1000) {
                console.log('Less than 1 min left so refresh');
                refreshToken();
            }
            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("token", token);
        } else {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem("token");
        }
    }, [token]);

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
};