import { createSlice } from "@reduxjs/toolkit";

interface UserState {
    user: any,
    token: any
    isAuthenticated: Boolean,
}

const initialState: UserState = {
    user: {},
    token: {},
    isAuthenticated: false,
}

export const userSlice: any = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        signup: (state, { payload }) => {
            state.user = payload
            state.isAuthenticated = true
        },
        saveTokens: (state, { payload }) => {
            state.token = payload
        },
        login: (state, { payload }) => {
            state.user = payload
            state.isAuthenticated = true
        },
        updateUser: (state, { payload }) => {
            return {
                ...state, 
                user: { ...state.user, ...payload }
            }
        },
    }
})

export const {
    signup,
    saveTokens,
    login,
    updateUser
} = userSlice.actions

export default userSlice.reducer