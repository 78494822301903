import env from '../../env.json';

export const rootURL = `${env.BASE_URL}`;
export const baseURL = `${rootURL}/api/v1/`;

// Endpoints
export const signup = 'signup'
export const login = 'login'
export const task = 'task'
export const taskDetail = 'task/detail/'
export const tasks = 'tasks'
export const firebaseToken = 'firebase-token'
export const category = 'category'
export const categories = 'categories'
export const categorySlash = 'category/'
export const userDfltCateg = 'user/default-categ'
export const userSlash = 'user/'
export const taskCateg = 'task/categ/'
export const taskStatus = 'task/status/'
export const taskDel = 'task/delete/'
export const refreshToken = 'refresh-token'
export const deleteTask = 'task/'
export const bday = 'bday'
export const bdays = 'bdays'
export const bdaySlash = 'bday/'
export const transport = 'transport'
export const transports = 'transports'
export const transportSlash = 'transport/'
export const transportComponent = 'transport-component'
export const transportComponents = 'transport-components'
export const transportComponentSlash = 'transport-component/'
export const dashboard = 'dashboard'
export const createInstance = 'create-instance'
export const getQrcode = 'get-qrcode'
export const bdayImg = 'bday-img'
export const bdayImgs = 'bday-imgs'