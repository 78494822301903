import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }: any) => { 
    if (localStorage.getItem("isAuthenticated")) {
        return children;
    }
    return <Navigate to="/login" replace />;

};

export default ProtectedRoute;