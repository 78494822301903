import apiService from './api_service';
import * as Api from '../../helpers/constant/api_const'
const tokenService = {
  addFirebaseToken: async (data: any) => {
    try {
      const response = await apiService.postData(Api.firebaseToken, data);
      return response;
    } catch (error) {
      console.error('Error adding firebase token:', error);
      throw error;
    }
  },
  refreshToken: async (data: any) => {
    try {
      const response = await apiService.postData(Api.refreshToken, data);
      return response;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  },
};

export default tokenService